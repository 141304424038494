import { Play } from "@shiftx/icons"
import { css, keyframes } from "@shiftx/styles"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { Image } from "../../components/Image"
import { Modal } from "../../components/Modal"
import { Section } from "../../components/Section"
import { Spacer } from "../../components/Spacer"
import { lessThan } from "../../utils/breakpoints"
import { Customer, customerReviews } from "./LovedByOurUsers"
import { Title2 } from "../../components/Text"

const slide = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
`

const SlidingLogos = ({ content }) => {
    return (
        <div
            css={css`
                height: 80px;
                display: inline-block;
                overflow: visible;
                transform-origin: left;
                transform: translateX(-100%);
                animation: 60s ${slide} infinite linear;
                img {
                    pointer-events: none;
                }
            `}
        >
            {content.logos.map((image, index) => {
                return (
                    <Image
                        key={index}
                        image={image.image}
                        alt={image.alt}
                        loading="eager"
                        css={css`
                            padding: 0 20px;
                            height: 80px;
                            width: auto;
                            ${lessThan(1)`
                                height: 120px;
                            `}
                            ${lessThan(0)`
                                height: 80px;
                                padding: 0;
                            `}
                        `}
                    />
                )
            })}
        </div>
    )
}

const LogoCarousel = ({ content }) => {
    return (
        <div
            css={css`
                display: inline-block;
                white-space: nowrap;
                height: 80px;
                width: 100%;
                overflow: hidden;
                mask: linear-gradient(
                    90deg,
                    transparent 0%,
                    #fff 20%,
                    #fff 80%,
                    transparent 100%
                );
                opacity: 1;
            `}
        >
            <SlidingLogos content={content} />
            <SlidingLogos content={content} />
        </div>
    )
}

const popRandomItems = (array, n) => {
    let copyArray = [...array]
    let arrayLength = array.length
    const newArray = []
    for (let i = 0; i < n; i++) {
        const index = Math.floor(Math.random() * arrayLength)
        const item = copyArray[index]
        newArray.push(item)
        copyArray = [
            ...copyArray.slice(0, index),
            ...copyArray.slice(index + 1, arrayLength),
        ]
        arrayLength--
    }
    return newArray
}

export const SocialProof = ({ content }) => {
    const [showVideo, setShowVideo] = useState(false)
    const [randomReviews, setRandomReviews] = useState([])

    useEffect(() => {
        const listener = event => {
            if (event.key === "Escape") {
                setShowVideo(false)
            }
        }

        if (showVideo) {
            document.addEventListener("keydown", listener)
        }

        return () => {
            document.removeEventListener("keydown", listener)
        }
    }, [showVideo])

    useEffect(() => {
        setRandomReviews(popRandomItems(customerReviews, 2))
    }, [])

    return (
        <Section>
            <motion.div
                initial={{ opacity: 0, translateY: 40 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true, amount: 0.1 }}
            >
                <Title2
                    css={css`
                        background: linear-gradient(
                            45deg,
                            var(--fg-high) 0%,
                            var(--fg-medium) 100%
                        );
                        background-size: 100%;
                        background-clip: text;
                        text-align: center;
                        text-fill-color: transparent;
                        margin-bottom: 40px;
                    `}
                >
                    {content.social.title}
                </Title2>
                <LogoCarousel content={content} />
                <Spacer height="24px" />
                <div
                    css={css`
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 24px;
                        ${lessThan(1)`
                                gap: 24px;
                            `}
                        ${lessThan(0)`
                                flex-direction: column;
                                gap: 12px;
                            `}
                    `}
                >
                    <div
                        onClick={() => setShowVideo(true)}
                        css={css`
                            position: relative;
                            display: flex;
                            flex: 1;
                            border-radius: 16px;
                            border: none;
                            cursor: pointer;
                            transition: all 0.4s ease-out;
                            :hover {
                                transform: translateY(-1px);
                                box-shadow: 0px 10px 20px #ddd;
                                .jajaja {
                                    background: rgba(255, 255, 255, 0.8);
                                    transform: scale(1.05);
                                    transition: all 0.4s ease-in-out;
                                }
                            }
                        `}
                    >
                        <div
                            className="jajaja"
                            css={css`
                                color: var(--light-gray-80087, var(--fg-high));
                                font-size: 14px;
                                font-family: Albert Sans;
                                font-weight: 600;
                                line-height: 24px;

                                position: absolute;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 8px;
                                z-index: 1;
                                top: calc(50% - 20px);
                                left: calc(50% - 60px);
                                border-radius: 24px;
                                background: rgba(255, 255, 255, 0.6);
                                backdrop-filter: blur(5px);
                                padding: 8px 24px;
                                transition: all 0.4s ease-in-out;
                            `}
                        >
                            <Play width={16} height={16} /> 2m 41s
                        </div>
                        <div
                            css={css`
                                color: var(--light-gray-0, #fff);
                                text-align: center;
                                font-size: 16px;
                                font-family: Albert Sans;
                                font-weight: 700;
                                line-height: 28px;
                                position: absolute;
                                color: white;
                                bottom: 24px;
                                left: 50%;
                                width: 100%;
                                transform: translatex(-50%);
                                text-shadow: 0 0 20px #000;
                            `}
                        >
                            Inselo closes sales faster with ShiftX
                        </div>
                        <Image
                            image={content.social.poster}
                            alt={content.social.alt}
                            css={css`
                                flex: 1;
                                object-fit: cover;
                                width: 360px;
                                height: auto;
                                border-radius: 16px;
                                ${lessThan(0)`
                                    width: 280px;
                                `}
                            `}
                        />
                    </div>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            flex: 1;
                            gap: 24px;
                            width: auto;
                            ${lessThan(1)`
                                gap: 24px;
                            `}
                            ${lessThan(0)`
                                flex-direction: column;
                                gap: 12px;
                            `}
                        `}
                    >
                        {randomReviews.map((review, index) => (
                            <Customer
                                key={index}
                                name={review.name}
                                tag={review.tag}
                                description={review.description}
                                link={review.link}
                                thumb={review.thumb}
                                css={css`
                                    flex: 1;
                                    width: auto;
                                    min-width: 250px;
                                `}
                            />
                        ))}
                    </div>
                </div>
            </motion.div>
            {showVideo && (
                <Modal
                    onClose={() => {
                        setShowVideo(false)
                    }}
                    css={css`
                        width: 80%;
                    `}
                >
                    <iframe
                        src={`${content.social.link}?autoplay=1`}
                        title={content.social.label}
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        loading="lazy"
                        css={css`
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            border: 0px;
                        `}
                    ></iframe>
                </Modal>
            )}
        </Section>
    )
}
