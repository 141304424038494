import { css } from "@emotion/react"
import { createAiProcess } from "@shiftx/ai"
import { analytics } from "@shiftx/analytics"
import { Events2 } from "@shiftx/analytics/events"
import { ArrowDiagonalDown, Pencil, Stars } from "@shiftx/icons"
import { baseFormat, normalizeReadable } from "@shiftx/readable"
import { TextStyle, getThemeProp } from "@shiftx/styles"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { AiRespons } from "../routes/AiPage/Ai"
import { lessThan } from "../utils/breakpoints"
import { Button, ButtonType, LoadingButton } from "./Button"
import { Section } from "./Section"
import { Spacer } from "./Spacer"

export const AiForm = ({ content }) => {
    const [controlledState, setControlledState] = useState(
        baseFormat({ items })
    )
    const [isLoading, setIsLoading] = useState(false)
    const [id, setId] = useState(crypto.randomUUID())
    const [useCase, setUseCase] = useState("textToFlow")

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm()

    const errorMessage = errors?.useCase?.message || errors?.file?.message

    const navigate = useRouter()

    return (
        <Section
            css={css`
                width: 100%;
            `}
        >
            <form
                onSubmit={handleSubmit(async values => {
                    setIsLoading(true)
                    analytics.track(Events2.client.GenerateAI, {
                        prompt: values.useCase,
                        actors: values.actors,
                        uploadedFile: !!values.file,
                        page: "homepage",
                    })

                    try {
                        await createAiProcess({
                            options: {
                                input: values.useCase,
                                files: values.file ? [values.file] : [],
                                actors: values.actors,
                                stream: true,
                            },
                            onChunk: value => {
                                const items = normalizeReadable({
                                    items: value.items,
                                })

                                setControlledState(
                                    baseFormat({
                                        name: value.name,
                                        items,
                                    })
                                )
                            },
                            onSuccess: ({ result }) => {
                                setId(id)
                                setIsLoading(false)
                                if (result) {
                                    localStorage.setItem(
                                        `sx:ai:${id}`,
                                        JSON.stringify(result)
                                    )
                                }
                            },
                        })
                    } catch (error) {
                        setError("useCase", { message: error })
                    }
                })}
                css={css`
                    width: 60%;
                    margin: 0 auto;

                    ${lessThan(1)`width:100%`}
                `}
            >
                <div
                    css={css`
                        display: grid;
                        gap: 24px;
                        place-items: center;
                        width: 100%;
                    `}
                >
                    {useCase === "textToFlow" && (
                        <TextToFlowInput
                            register={register}
                            setValue={setValue}
                            isLoading={isLoading}
                        />
                    )}

                    {errorMessage && (
                        <div
                            css={css`
                                font-size: 14px;
                                color: var(--red-500);
                            `}
                        >
                            {errors?.useCase?.message || errors?.file?.message}
                        </div>
                    )}
                </div>
            </form>

            <Spacer height="24px" />

            {controlledState?.items?.length > 0 && (
                <div
                    css={css`
                        margin: 0 auto;
                        text-align: center;
                        margin-top: 24px;

                        ${lessThan(0)`                        
                        overflow: hidden;
`}
                    `}
                >
                    <div
                        css={css`
                            left: 50%;
                            margin-left: -50vw;
                            margin-right: -50vw;
                            max-width: 100vw;
                            position: relative;
                            right: 50%;
                            width: 100vw;
                            position: relative;
                        `}
                    >
                        <AiRespons
                            controlledState={controlledState}
                            theme={"light"}
                            isLoading={isLoading}
                            options={{ height: "350px" }}
                        >
                            {/*  <div
                                css={css`
                                    height: 30px;
                                    width: 100%;
                                    position: absolute;
                                    background: linear-gradient(
                                        transparent,
                                        #f5f5f5
                                    );
                                    bottom: 0px;
                                    transform: translateY(0%);
                                    margin: 0 auto;
                                    text-align: center;
                                    z-index: 1000;
                                `}
                            ></div> */}

                            <div
                                css={css`
                                    height: 30px;
                                    width: 100%;
                                    position: absolute;
                                    background: linear-gradient(
                                        #f5f5f5,
                                        transparent
                                    );
                                    top: 0px;
                                    transform: translateY(0%);
                                    margin: 0 auto;
                                    text-align: center;
                                    z-index: 1000;
                                `}
                            ></div>

                            <div
                                css={css`
                                    margin-top: 24px;
                                    position: absolute;
                                    bottom: 0px;
                                    left: 0;
                                    right: 0;
                                    z-index: 1000;
                                    width: 100%;
                                `}
                            >
                                <div
                                    css={css`
                                        position: absolute;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        bottom: -30px;

                                        background: #fff;
                                        box-shadow: 0px 0px 40px 0px
                                            rgba(0, 0, 0, 0.04);
                                        border-radius: 8px;
                                        display: flex;
                                        align-items: center;
                                        width: 640px;
                                        padding: 8px 8px 8px 16px;
                                        justify-content: space-between;
                                        font-weight: 500;

                                        ${lessThan(
                                            0
                                        )`left: auto; bottom: 20px; width: auto; transform: none`}
                                    `}
                                >
                                    <div
                                        css={css`
                                            ${lessThan(0)`display:none`}
                                        `}
                                    >
                                        Get started for free
                                    </div>

                                    {!isLoading && (
                                        <Link
                                            css={css`
                                                ${isLoading &&
                                                css`
                                                    pointer-event: none;
                                                    cursor: default;
                                                `}
                                            `}
                                            href={
                                                isLoading
                                                    ? ""
                                                    : `/processes/new?importId=${id}`
                                            }
                                            onClick={() => {
                                                if (
                                                    !localStorage.getItem(
                                                        `sx:ai:${id}` // eslint-disable-line
                                                    )
                                                ) {
                                                    localStorage.setItem(
                                                        `sx:ai:${id}`,
                                                        JSON.stringify(
                                                            readableItems
                                                        )
                                                    )

                                                    analytics.track(
                                                        Events2.client
                                                            .EditInShiftx,
                                                        {}
                                                    )
                                                }
                                            }}
                                        >
                                            <EditInShiftXbutton
                                                isLoading={isLoading}
                                            />
                                        </Link>
                                    )}

                                    {isLoading && (
                                        <EditInShiftXbutton
                                            isLoading={isLoading}
                                        />
                                    )}
                                </div>
                            </div>
                        </AiRespons>
                    </div>
                </div>
            )}
        </Section>
    )
}

const EditInShiftXbutton = ({ isLoading }) => {
    return (
        <Button
            variant={ButtonType.Primary}
            css={css`
                background-color: #000;
                padding: 8px 20px;
                gap: 8px;
                :hover {
                    background-color: #000;
                }

                ${isLoading &&
                css`
                    pointer-event: none;
                    background-color: var(--fg-300);
                    pointer-events: none;

                    :hover,
                    :focus {
                        background-color: var(--fg-300);
                    }
                `}
            `}
            onMouseDown={event => {
                event.preventDefault()
            }}
        >
            <Pencil width={16} height={16} />
            Edit in ShiftX
        </Button>
    )
}

const examplePrompts = [
    "Onboard a new user to our platform, and try to convert them to a paying customer",
    "Process a refund for a customer for a online retail ",
    "Support a customer with a technical issue",
]

export const TextToFlowInput = ({ register, setValue, isLoading }) => {
    const [focusRef, setRef] = useState(null)
    useEffect(() => {
        if (focusRef) {
            focusRef.focus({ preventScroll: true })
        }
    }, [focusRef])

    const { ref, ...registerField } = register("useCase", {
        required: "Description is required",
    })

    return (
        <div
            css={css`
                width: 100%;
                margin: 0 auto;
            `}
        >
            <div
                css={css`
                    position: relative;
                    background-color: #fff;
                    border: 1px solid var(--fg-200);
                    border-radius: 48px;
                `}
            >
                <input
                    ref={e => {
                        ref(e)
                        setRef(e)
                    }}
                    {...registerField}
                    autocomplete="off"
                    placeholder={"Describe the flow you want to generate.."}
                    autoFocus={true}
                    defaultValue={""}
                    css={css`
                        min-height: 120px;
                        font-size: 16px;
                        box-sizing: border-box;
                        border: none;
                        resize: none;
                        min-height: 50px;
                        width: 70%;
                        border-radius: 48px;
                        background-color: #fff;
                        border: transparent;
                        padding: 24px;
                        margin: 0;
                        text-overflow: ellipsis;

                        ${lessThan(1)`
                            padding: 16px;
                        `}

                        ::placeholder {
                            color: var(--fg-low);
                        }

                        &:focus {
                            outline: none;

                            ::placeholder {
                                color: var(--fg-low);
                                opacity: 0.5;
                            }
                        }

                        :read-only {
                            color: var(--fg-low);
                        }
                    `}
                />
                <LoadingButton
                    isLoading={isLoading}
                    variant={ButtonType.Primary}
                    type="submit"
                    css={css`
                        gap: 8px;
                        margin: 0px 0;
                        position: absolute;
                        right: 8px;
                        top: 8px;
                        border-radius: 48px;
                        background-color: #000;

                        ${lessThan(0)`padding: 4px 8px;`}

                        :hover {
                            background-color: #000;
                        }
                    `}
                >
                    <Stars width={16} height={16} />
                    Generate flow
                </LoadingButton>
            </div>

            <div
                css={css`
                    font-size: 13px;
                    text-align: center;
                    margin: 8px 0;

                    ${lessThan(0)`display:none`}
                `}
            >
                Or try one of the examples:
            </div>

            <div
                css={css`
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin-top: 16px;
                    font-size: 14px;
                    font-weight: 500;
                    margin: 0 auto;
                    ${lessThan(0)`display:none`}
                `}
            >
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        margin: 0 auto;

                        ${lessThan(1)`display:grid;`}
                    `}
                >
                    {examplePrompts.map(prompt => {
                        return (
                            <Example
                                key={prompt}
                                onClick={() => {
                                    setValue("useCase", prompt)
                                    analytics.track(
                                        Events2.client.SelectExamplePrompt,
                                        { prompt }
                                    )
                                }}
                            >
                                {prompt}
                            </Example>
                        )
                    })}
                    <Link href="/ai">
                        <div
                            css={css`
                                svg {
                                    transform: rotate(-45deg);
                                }
                                display: flex;
                                align-items: center;
                                gap: 2px;
                                font-size: 14px;
                            `}
                        >
                            <ArrowDiagonalDown height={16} width={16} /> More
                            settings
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

const Example = ({ children, onClick }) => {
    return (
        <div
            css={theme => css`
                border-radius: 48px;
                font-size: 14px;
                background-color: var(--blue-100);
                color: var(--blue-500);
                padding: 4px 12px;
                display: inline-block;
                width: 154px;
                cursor: pointer;
                user-select: none;

                :hover {
                    background-color: var(--blue-500);
                    color: var(--blue-100);
                }

                ${getThemeProp(theme, TextStyle.Ellipsis)}
            `}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

const readableItems = {
    name: "User Onboarding and Conversion Process",
    items: [
        {
            narrative:
                '<actor name="HR system" icon="fas-gears" description="system for hr management" category="company"></actor> creates a new user account',
        },
        {
            narrative:
                '<actor name="New User" icon="fas-user" description="the individual who is being onboarded" category="customer"></actor> receives welcome email and logs in to the platform',
        },
        {
            narrative:
                '<actor name="Onboarding Guide" icon="fas-user-ninja" description="assistant that helps new users understand the platform" category="system"></actor> provides a tour of the platform features',
        },
        {
            narrative:
                '<actor name="Marketing System" icon="fas-robot" description="automated system for marketing" category="company"></actor> sends educational content and promotional offers',
        },
        {
            narrative:
                'Is <actor name="New User" icon="fas-user" description="the individual who is being onboarded" category="customer"></actor> engaging with the platform?',
            splitType: "decision",
            paths: [
                {
                    label: "Engaged",
                    items: [
                        {
                            narrative:
                                '<actor name="Sales Team" icon="fas-user-group" description="team responsible for sales and customer acquisition" category="company"></actor> reaches out with a personalized offer',
                        },
                    ],
                    probability: 60.0,
                    onSuccess: { kind: "none" },
                },
                {
                    label: "Not Engaged",
                    items: [
                        {
                            narrative:
                                '<actor name="Marketing System" icon="fas-robot" description="automated system for marketing" category="company"></actor> sends a follow-up survey to understand user needs',
                        },
                        {
                            narrative:
                                '<actor name="Product Team" icon="fas-users" description="team responsible for product development and user experience" category="company"></actor> reviews survey results and plans product improvements',
                        },
                    ],
                    probability: 40.0,
                    onSuccess: {
                        kind: "loop",
                        loopToNarrative:
                            '<actor name="Marketing System" icon="fas-robot" description="automated system for marketing" category="company"></actor> sends educational content and promotional offers',
                    },
                },
            ],
        },
    ],
}

const items = normalizeReadable(readableItems)
