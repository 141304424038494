import { css } from "@shiftx/styles"

export const Hierarchy = () => {
    return (
        <svg
            width="600"
            height="264"
            viewBox="0 0 600 264"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            css={css`
                width: 100%;
                height: fit-content;
            `}
        >
            <rect width="600" height="264" fill="white" />
            <rect
                x="497.5"
                y="216.5"
                width="102"
                height="47"
                rx="23.5"
                stroke="#D1D1D1"
            />
            <text x="520" y="245" fill="#000">
                Finance
            </text>

            <rect
                x="224.5"
                y="144.5"
                width="102"
                height="47"
                rx="23.5"
                stroke="#D1D1D1"
            />
            <text x="245" y="173" fill="#000">
                Finance
            </text>

            <rect
                x="15.5"
                y="144.5"
                width="160"
                height="47"
                rx="23.5"
                stroke="#D1D1D1"
            />
            <text x="32" y="173" fill="#000">
                Retention funnel
            </text>
            <rect
                x="437.5"
                y="72.5"
                width="160"
                height="47"
                rx="23.5"
                stroke="#D1D1D1"
            />
            <text x="455" y="101" fill="#000">
                Retention funnel
            </text>
            <rect
                x="248.5"
                y="72.5"
                width="140"
                height="47"
                rx="23.5"
                stroke="#D1D1D1"
            />
            <text x="265" y="101" fill="#000">
                Payment flow
            </text>
            <rect
                x="177.5"
                y="0.5"
                width="187"
                height="47"
                rx="23.5"
                stroke="#D1D1D1"
            />

            <text x="195" y="28" fill="#000">
                Customer aquisition
            </text>

            <rect
                x="249.5"
                y="216.5"
                width="199"
                height="47"
                rx="23.5"
                stroke="#D1D1D1"
            />
            <text x="265" y="245" fill="#000">
                Employee onboarding
            </text>
            <path d="M327 168H375" stroke="#D1D1D1" strokeLinecap="round" />
            <path d="M449 240H497" stroke="#D1D1D1" strokeLinecap="round" />
            <path
                d="M176 168C216 168 208 240 248 240"
                stroke="#D1D1D1"
                strokeLinecap="round"
            />
            <path
                d="M365 24C405 24 397 96 437 96"
                stroke="#D1D1D1"
                strokeLinecap="round"
            />
            <path
                d="M176 168C216 168 208 96 248 96"
                stroke="#D1D1D1"
                strokeLinecap="round"
            />
            <rect
                x="375.5"
                y="144.5"
                width="187"
                height="47"
                rx="23.5"
                stroke="#D1D1D1"
            />
            <text x="395" y="173" fill="#000">
                Customer aquisition
            </text>
        </svg>
    )
}
