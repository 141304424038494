import { css } from "@shiftx/styles"
import { USP } from "../../components/block/USP"
import { Section } from "../../components/Section"
import { motion } from "framer-motion"
import { SubTitle, Title2Gradient } from "../../components/Text"
import { BlockHeaderSpacer } from "../../components/Spacer"
import { lessThan } from "../../utils/breakpoints"
import { ActorNeurons } from "../../components/visual/ActorNeurons"
import { TimeAnimation } from "../../components/visual/TimeAnimation"
import { splitTitleIntoBlackAndBlue } from "../../utils/title"

export const SmarterKnowledge = ({ content }) => {
    const titleArray = splitTitleIntoBlackAndBlue(content.title, content.blue)

    return (
        <Section>
            <motion.div
                initial={{ opacity: 0, translateY: 40 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true, amount: 0.1 }}
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    gap: 16px;
                `}
            >
                <Title2Gradient>
                    {titleArray.map((item, index) => {
                        return item.color === "black" ? (
                            <span key={index}>{item.text}</span>
                        ) : (
                            <span
                                key={index}
                                css={css`
                                    color: var(--brand-base);
                                `}
                            >
                                {item.text}
                            </span>
                        )
                    })}
                </Title2Gradient>
                <SubTitle
                    css={css`
                        max-width: 540px;
                    `}
                >
                    {content?.tagline}
                </SubTitle>
            </motion.div>
            <BlockHeaderSpacer />
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    ${lessThan(0)`
                        gap: 12px;
                    `}
                `}
            >
                <USP
                    textFirst={true}
                    color="orange"
                    label={content.blocks[0].label}
                    title={content.blocks[0].title}
                    preamble={content.blocks[0].description}
                    illustration={<ActorNeurons />}
                />
                <USP
                    textFirst={false}
                    color="purple"
                    label={content.blocks[1].label}
                    title={content.blocks[1].title}
                    preamble={content.blocks[1].description}
                    illustration={<TimeAnimation />}
                />
            </div>
        </Section>
    )
}
