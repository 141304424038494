import { css } from "@shiftx/styles"
import { motion } from "framer-motion"
import { forwardRef, useRef } from "react"
import { lessThan } from "../../utils/breakpoints"
import { useRefResize } from "../../hooks/useRefResize"

const Checkmark = ({ size = 24 }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_1_2814)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.6666 14.6669L16.1544 7.90674L16.6666 8.33353L17.1787 8.76032L10.6666 16.6669L6.86182 12.8049L7.33322 12.3335L7.80463 11.8621L10.6666 14.6669Z"
                fill="white"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1_2814"
                    x1="0"
                    y1="0"
                    x2="24"
                    y2="24"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#262690" />
                    <stop offset="1" stopColor="#4040F0" />
                </linearGradient>
            </defs>
        </svg>
    )
}

const Vault = forwardRef((props, ref) => {
    return (
        <svg
            ref={ref}
            width="100%"
            height="100%"
            viewBox="0 0 540 424"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M315.747 33.4241C316.932 37.1475 320.199 40 324.106 40H524C532.837 40 540 47.1634 540 56V408C540 416.837 532.837 424 524 424H16C7.16345 424 0 416.837 0 408V56C0 47.1635 7.16344 40 16 40H215.894C219.801 40 223.068 37.1475 224.253 33.4241C230.424 14.0409 248.572 0 270 0C291.428 0 309.576 14.0409 315.747 33.4241Z"
                fill="url(#paint0_linear_0_1)"
                fillOpacity="0.04"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M224.253 62.5759C223.068 58.8525 219.801 56 215.894 56H24C19.5817 56 16 59.5817 16 64V400C16 404.418 19.5817 408 24 408H516C520.418 408 524 404.418 524 400V64C524 59.5817 520.418 56 516 56H324.106C320.199 56 316.932 58.8525 315.747 62.5759C309.576 81.9591 291.428 96 270 96C248.572 96 230.424 81.9591 224.253 62.5759Z"
                fill="white"
                stroke="rgba(64, 64, 240, 1)"
            />
            <circle
                cx="270"
                cy="48"
                r="32"
                fill="white"
                stroke="rgba(64, 64, 240, 1)"
            />
            <path
                id="lock"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M270 38C267.239 38 265 40.2386 265 43V45H267V43C267 41.3431 268.343 40 270 40C271.657 40 273 41.3431 273 43V45H275V43C275 40.2386 272.761 38 270 38ZM265 47C263.343 47 262 48.3431 262 50V55C262 56.6569 263.343 58 265 58H275C276.657 58 278 56.6569 278 55V50C278 48.3431 276.657 47 275 47H265ZM264 50C264 49.4477 264.448 49 265 49H275C275.552 49 276 49.4477 276 50V55C276 55.5523 275.552 56 275 56H265C264.448 56 264 55.5523 264 55V50Z"
                fill="rgba(64, 64, 240, 1)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_0_1"
                    x1="0"
                    y1="0"
                    x2="330.575"
                    y2="549.897"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#262690" />
                    <stop offset="1" stopColor="#4040F0" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_0_1"
                    x1="16"
                    y1="56"
                    x2="333.191"
                    y2="560.623"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#467EEB" />
                    <stop offset="1" stopColor="#652D7F" />
                </linearGradient>
            </defs>
        </svg>
    )
})



export const SecurityFeatures = () => {
    const ref = useRef(null)
    const container = useRefResize(ref)

    return (
        <div
            css={css`
                position: relative;
                display: block;
                width: 100%;
                max-width: 500px;
                height: auto;
                ${lessThan(1)`
                    padding: 24px;
                    padding-top: 0;
                `}
                ${lessThan(0)`
                    padding: 12px;
                    padding-top: 0;
                `}
            `}
        >
            <Vault ref={ref} />
            <div
                css={css`
                    display: flex;
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    left: 50%;
                    width: ${container.w}px;
                    height: ${container.h}px;
                    padding: 18% 10% 0 10%;
                    --scaleTransform: calc(${container.w} / 443);
                    --scaleWidth: calc(443 / ${container.w});
                `}
            >
                <motion.div
                    initial={"hidden"}
                    whileInView={"show"}
                    transition={{
                        staggerChildren: 0.2,
                    }}
                    viewport={{ once: true, amount: 1 }}
                    css={css`
                        transform: scale(var(--scaleTransform));
                        transform-origin: top left;
                        > :not(:last-child) {
                            border-bottom: 1px solid var(--fg-200);
                        }
                    `}
                >
                    <Feature title={"GDPR Compliant"} />
                    <Feature title={"Encrypted connections"} />
                    <Feature title={"SAML single sign-on"} />
                    <Feature title={"Awesome support"} />
                    <Feature title={"Secure data in the cloud"} />
                </motion.div>
            </div>
        </div>
    )
}

const Feature = ({ title }) => {
    const variant = {
        show: {
            opacity: 1,
            translateX: 0,
            transition: {
                opacity: {
                    ease: "easeIn",
                    duration: 0.3,
                },
                translateX: {
                    ease: "linear",
                    duration: 0.3,
                },
            },
        },
        hidden: {
            opacity: 0,
            translateX: 40,
        },
    }
    return (
        <motion.div
            css={css`
                display: flex;
                align-items: center;
                gap: 16px;
                width: 335px;
                height: auto;
                padding: 8px 0;
                font-size: 16px;
            `}
            variants={variant}
        >
            <Checkmark size={20} />
            {title}
        </motion.div>
    )
}
