import { analytics } from "@shiftx/analytics"
import { css, styled } from "@shiftx/styles"
import { motion, useAnimation } from "framer-motion"
import { useEffect } from "react"
import { LinkButton } from "../../components/LinkButton"
import { Section } from "../../components/Section"
import { Spacer } from "../../components/Spacer"
import { SubTitle } from "../../components/Text"
import { Title } from "../../components/Text"
import { lessThan } from "../../utils/breakpoints"
import { AiForm } from "../../components/AiForm"
import { Wave } from "../../components/CompareFlows"

const Word = styled(motion.span)`
    display: inline-block;
    margin-right: 0.25em;
    white-space: nowrap;
`

const Character = styled(motion.span)`
    display: inline-block;
    margin-right: 0em;
`
export const HeroTitle = ({ content }) => {
    const text = "AI-generated flows from basic instructions" //content.title
    const ctrls = useAnimation()

    useEffect(() => {
        ctrls.start("visible")
    }, [ctrls])

    const wordAnimation = {
        hidden: {},
        visible: {},
    }

    const characterAnimation = {
        hidden: {
            opacity: 0.01,
            y: `0.25em`,
        },
        visible: {
            opacity: 1,
            y: `0em`,
            transition: {
                duration: 0.5,
                ease: [0.2, 0.65, 0.3, 0.9],
            },
        },
    }

    return (
        <div
            css={css`
                margin-top: 0px;
                ${lessThan(1)`margin-top:0px`}
            `}
        >
            <Title
                aria-label={text}
                role="heading"
                css={css`
                    /*   background: linear-gradient(
                        45deg,
                        #262690 20%,
                        #4040f0 80%
                    ); */
                    font-size: 84px;
                    line-height: 90px;
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    text-align: center;
                    max-width: 960px;
                    margin-left: auto;
                    margin-right: auto;

                    ${lessThan(1)`
                        max-width: 600px;
                    `}

                    ${lessThan(0)`
                        max-width: 400px;
                    `}
                `}
            >
                {text.split(" ").map((word, index) => {
                    return (
                        <Word
                            aria-hidden="true"
                            key={index}
                            initial="hidden"
                            animate={ctrls}
                            variants={wordAnimation}
                            transition={{
                                delayChildren: index * 0.25,
                                staggerChildren: 0.05,
                            }}
                        >
                            {word.split("").map((character, index) => {
                                return (
                                    <Character
                                        aria-hidden="true"
                                        key={index}
                                        variants={characterAnimation}
                                        css={css`
                                            background: linear-gradient(
                                                92deg,
                                                rgba(17, 17, 17, 0.88) 10.27%,
                                                rgba(34, 34, 34, 0.88) 93.03%
                                            );

                                            background-size: 100%;
                                            background-clip: text;
                                            -webkit-text-fill-color: transparent;
                                        `}
                                    >
                                        {character}
                                    </Character>
                                )
                            })}
                        </Word>
                    )
                })}
            </Title>
            <Spacer height="24px" />
            <motion.div
                initial={{ opacity: 0.01, translateY: 24 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 0.8, delay: 0.7 }}
                viewport={{ once: true, amount: 0.3 }}
            >
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                    `}
                >
                    <SubTitle
                        css={css`
                            animation: ease-out;
                            text-align: center;
                            max-width: 600px;
                            ${lessThan(1)`
                                max-width: 400px;
                                `}
                        `}
                    >
                        Get started in seconds. Optimize operations with
                        easy-to-use process mapping, ensure compliance and
                        increase customer satisfaction
                    </SubTitle>
                </div>
            </motion.div>
            <Spacer height="48px" />
            <AiForm content={content} />
        </div>
    )
}
