import { css } from "@shiftx/styles"
import { Section } from "../../components/Section"
import { Title2 } from "../../components/Text"

export const customerReviews = [
    {
        name: "Alex Bass",
        tag: "@alexhbass",
        description:
            "Wish I had this a while ago, was playing with processplan and LucidChart and so many others for so long. Having something quick and reasonably priced to map out processes is so needed",
        link: "https://www.producthunt.com/products/shiftx?comment=1819775#shiftx",
        thumb: "https://ph-avatars.imgix.net/3523155/original?auto=format&auto=compress&codec=mozjpeg&cs=strip&w=36&h=36&fit=crop&dpr=2",
    },
    {
        name: "Johan Steneros",
        tag: "@jsteneros",
        description:
            "This is super useful. Miro is great but these features you have are amazing. Having Actors and being table to enter data in a dialog box seems like it simplifies / automates everything much more. Signing up",
        link: "https://www.producthunt.com/products/shiftx?comment=1819788#shiftx",
        thumb: "https://ph-avatars.imgix.net/38670/original?auto=format&auto=compress&codec=mozjpeg&cs=strip&w=36&h=36&fit=crop&dpr=2",
    },
    {
        name: "Thomas",
        tag: "@thoaud",
        description:
            "I've been smiling all day 😄. Why? Because today was my first day using @shiftxcom at Servebolt. Insanely valuable to be able to easily visualize complex processes in a way every @servebolt employee can understand.",
        link: "https://twitter.com/thoaud/status/1179810406409363462",
        thumb: "https://pbs.twimg.com/profile_images/1437783868640202759/NZwpo6_g_x96.jpg",
    },
    {
        name: "Hilde Rannem Rostad",
        tag: "Director Digitalization of Financial services",
        description:
            "“With Visio, we'd be left with a static image of our as-is situation, whereas with ShiftX, we've now got a dynamic first sketch that invites us to iterate on our processes.”",
        thumb: "https://cdn.sanity.io/images/uq4w4z8u/production/668ecf9b694505ba016718bacccb9896954a3c23-596x596.jpg?w=2000&h=2000&fit=max",
        link: "/customers/obos-case/",
    },
]

export const Customer = ({
    name,
    tag,
    description,
    link = "",
    service = "producthunt",
    thumb = "",
    ...rest
}) => {
    const href = link ? { href: link, target: "_blank" } : {}
    return (
        <a {...href} {...rest}>
            <div
                css={css`
                    border: 1px solid var(--fg-300);
                    border-radius: 16px;
                    padding: 24px;
                    font-family: "Albert Sans", sans-serif;
                    background-color: var(--bg-primary);
                    font-size: 15px;
                    line-height: 24px;
                    transition: all 0.4s ease-out;
                    :hover {
                        border: 1px transparent solid;
                        transform: translateY(-1px);
                        box-shadow: 0px 10px 20px #ddd;
                    }
                    height: 100%;
                `}
            >
                <div
                    css={css`
                        display: grid;
                        grid-template-columns: 48px auto;
                        align-items: center;
                        padding-bottom: 16px;
                        margin-bottom: 8px;
                        gap: 12px;
                    `}
                >
                    <div
                        css={css`
                            width: 48px;
                            height: 48px;
                            border-radius: 50%;
                            background: red;
                            overflow: hidden;
                            background-image: url(${thumb});
                            background-position: center center;
                            background-size: cover;
                        `}
                    ></div>
                    <div
                        css={css`
                            line-height: 24px;
                        `}
                    >
                        <div>{name}</div>
                        <div
                            css={css`
                                color: var(--fg-medium);
                                font-size: 14px;
                            `}
                        >
                            {tag}
                        </div>
                    </div>
                </div>
                <div>{description}</div>
            </div>
        </a>
    )
}

export const LovedByOurUsers = () => {
    return (
        <div
            css={css`
                margin-top: var(--section-margin-small);
                padding: 80px 0px;
            `}
        >
            <Section>
                <Title2
                    css={css`
                        text-align: center;
                        background: linear-gradient(
                            98deg,
                            var(--fg-high) 10.74%,
                            var(--fg-medium) 77.25%
                        );

                        background-size: 100%;
                        background-clip: text;
                        text-fill-color: transparent;
                    `}
                >
                    Loved by our users
                </Title2>
                <div
                    css={css`
                        display: grid;
                        grid-template-columns: repeat(
                            auto-fit,
                            minmax(20rem, 1fr)
                        );
                        gap: 24px;
                        margin-top: 48px;
                    `}
                >
                    {customerReviews.map((review, index) => (
                        <Customer
                            key={index}
                            name={review.name}
                            tag={review.tag}
                            description={review.description}
                            link={review.link}
                            thumb={review.thumb}
                        />
                    ))}
                </div>
            </Section>
        </div>
    )
}
