import { css } from "@shiftx/styles"
import { Fragment } from "react"

export const Modal = ({ children, onClose, ...props }) => {
    return (
        <Fragment>
            <div
                tabIndex={0}
                css={css`
                    z-index: 100000;
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background-color: rgba(0, 0, 0, 0.8);
                    display: grid;
                    place-items: center;
                    padding: 0 160px;
                `}
                onClick={e => {
                    e.stopPropagation()
                    onClose()
                }}
            ></div>

            <div
                css={css`
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    position: fixed;
                    overflow: hidden;
                    z-index: 10000000;
                    width: 400px;
                    margin: 40px auto;
                `}
                {...props}
            >
                <div css={css``}>{children}</div>
            </div>
        </Fragment>
    )
}
