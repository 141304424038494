import { css } from "@shiftx/styles"
import Link from "next/link"
import React, { Fragment } from "react"
import { Asset } from "../../components/Asset"
import { Button } from "../../components/Button"
import { Main } from "../../components/Main"
import { Section } from "../../components/Section"
import { SeeForYourself } from "../../components/SeeForYourself"
import { Title2 } from "../../components/Text"
import { greaterThan, lessThan } from "../../utils/breakpoints"
import { RichText } from "../Case"
import { CustomerCasePreview } from "../CustomerPage/CasePreview"
import { HighlightSection } from "../HomePage"

export const getColor = color => {
    switch (color) {
        case "orange":
            return "#FC7124"
        case "yellow":
            return "#FAAD18"
        case "red":
            return "#F04858"
        case "green":
            return "#2FB264"
        case "blue":
            return "#467EEB"
        case "purple":
            return "#A84BD4"

        default:
            break
    }
}

export const getGradient = color => {
    return ` background: linear-gradient(
                                113.96deg,
                                ${getColor(color)}40 0%,
                                ${getColor(color)}30 100%
                            );`
}

const Block2 = ({ content, asset, color }) => {
    return (
        <div
            css={css`
                margin-top: var(--section-margin-inside);
            `}
        >
            {asset?.[0] && (
                <div>
                    <div
                        css={css`
                            border-radius: 8px;
                            overflow: hidden;
                            background: linear-gradient(
                                113.96deg,
                                ${getColor(color)}40 0%,
                                ${getColor(color)}30 100%
                            );
                            padding: 80px;
                            ${lessThan(1)`padding:24px;`}
                        `}
                    >
                        <Asset asset={asset[0]} />
                    </div>
                </div>
            )}

            <div
                css={css`
                    margin: 0 auto;
                    margin-top: var(--section-margin-inside);
                    h2 {
                        margin-top: 24px;
                    }
                    ${greaterThan(1)`
                        display: grid;
                        grid-template-columns: repeat(12, 1fr);
                    `}
                `}
            >
                <RichText
                    content={content}
                    css={css`
                        ${greaterThan(1)`
                            grid-column-start: 5;
                            grid-column-end: 11;
                        `};

                        p {
                            margin: 0;
                            margin-top: 16px;
                        }

                        h2 {
                            margin-top: 40px;
                        }
                    `}
                />
            </div>
        </div>
    )
}

export const ProductPage = ({ content, cases }) => {
    return (
        <Fragment>
            <Main
                css={css`
                    margin-bottom: 0;
                `}
            >
                <Section>
                    <div
                        css={css`
                            grid-gap: var(--grid-gap);
                            display: grid;
                            grid-template-columns: repeat(
                                auto-fit,
                                minmax(20rem, 1fr)
                            );
                        `}
                    >
                        <div>
                            <Title2
                                css={css`
                                    margin-bottom: 40px;
                                `}
                            >
                                {content.title}
                            </Title2>
                            <RichText content={content.body} />
                        </div>

                        {content?.asset?.[0] && (
                            <div>
                                <Asset asset={content.asset[0]} />
                            </div>
                        )}
                    </div>
                </Section>

                {content.block && (
                    <Section
                        css={css`
                            margin-top: var(--section-margin-inside);

                            & > a:nth-child(3) {
                                border-bottom: 1px solid var(--fg-200);
                            }
                        `}
                    >
                        {content.block.map((section, index) => {
                            return (
                                <a
                                    href={`#section_${index + 1}`}
                                    css={css`
                                        border-top: 1px solid var(--fg-200);
                                        padding: 24px 0;
                                        display: flex;
                                        :hover {
                                            color: var(--brand-base);
                                        }
                                    `}
                                >
                                    {section.title}
                                </a>
                            )
                        })}
                    </Section>
                )}

                {content?.block?.map?.((section, index) => {
                    return (
                        <div
                            css={css`
                                margin-top: var(--section-margin);
                            `}
                        >
                            <Section>
                                <div>
                                    <div
                                        css={css`
                                            ${greaterThan(0)`max-width: 70%;`}
                                        `}
                                    >
                                        <a name={`section_${index + 1}`}>
                                            <Title2
                                                css={css`
                                                    margin-bottom: 24px;
                                                    ${greaterThan(
                                                        0
                                                    )`max-width: 70%;`}
                                                `}
                                            >
                                                {section.title}
                                            </Title2>
                                        </a>

                                        <p>{section.lead}</p>
                                    </div>

                                    {index === 0 && (
                                        <Block2
                                            content={section.content}
                                            asset={section.asset}
                                            color={section.color}
                                        />
                                    )}

                                    {index === 1 && (
                                        <Block2
                                            content={section.content}
                                            asset={section.asset}
                                            color={section.color}
                                        />
                                    )}

                                    {index === 2 && (
                                        <Block2
                                            content={section.content}
                                            asset={section.asset}
                                            color={section.color}
                                        />
                                    )}
                                </div>
                            </Section>

                            {index === 0 && (
                                <Section
                                    css={css`
                                        margin-top: var(--section-margin);
                                    `}
                                >
                                    <div
                                        css={css`
                                            display: grid;
                                            grid-template-columns: repeat(
                                                auto-fit,
                                                minmax(20rem, 1fr)
                                            );
                                            gap: 2rem;
                                        `}
                                    >
                                        {cases.map((singleCase, index) => {
                                            return (
                                                <CustomerCasePreview
                                                    key={index}
                                                    content={singleCase}
                                                />
                                            )
                                        })}
                                    </div>

                                    <div
                                        css={css`
                                            margin-top: 80px;
                                            display: flex;
                                            justify-content: center;
                                        `}
                                    >
                                        <Link href="customers">
                                            <Button>
                                                Browse all use cases
                                            </Button>
                                        </Link>
                                    </div>
                                </Section>
                            )}
                        </div>
                    )
                })}

                {content?.feature?.map?.((feature, index) => {
                    const isOdd = index % 2 == 0

                    return (
                        <HighlightSection
                            key={index}
                            title={feature.title}
                            asset={feature.asset}
                            color={feature.color}
                            isOdd={isOdd}
                        >
                            <RichText content={feature.content} />
                        </HighlightSection>
                    )
                })}

                <SeeForYourself />
            </Main>
        </Fragment>
    )
}
