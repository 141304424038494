import { css } from "@shiftx/styles"
import { motion } from "framer-motion"
import { Section } from "../../components/Section"
import { colorSchemeUSP } from "../../components/block/USP"
import { BlockHeaderSpacer } from "../../components/Spacer"
import {
    Paragraph,
    SubTitle,
    Title2Gradient,
    Title3,
} from "../../components/Text"
import { lessThan } from "../../utils/breakpoints"
import { GlobeIntegrations } from "../../components/visual/GlobeIntegrations"
import { Hierarchy } from "../../components/visual/Hierarchy"
import { SecurityFeatures } from "../../components/visual/SecurityFeatures"
import { LinkButton } from "../../components/LinkButton"
import { splitTitleIntoBlackAndBlue } from "../../utils/title"
import { Badge } from "../../components/Badge"

export const Management = ({ content }) => {
    const titleArray = splitTitleIntoBlackAndBlue(content?.title, content?.blue)

    return (
        <Section>
            <motion.div
                initial={{ opacity: 0, translateY: 40 }}
                whileInView={{ opacity: 1, translateY: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true, amount: 0.1 }}
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    gap: 16px;
                `}
            >
                <Title2Gradient>
                    {titleArray.map((item, index) => {
                        return item.color === "black" ? (
                            <span key={index}>{item.text}</span>
                        ) : (
                            <span
                                key={index}
                                css={css`
                                    color: var(--brand-base);
                                `}
                            >
                                {item.text}
                            </span>
                        )
                    })}
                </Title2Gradient>
                <SubTitle
                    css={css`
                        max-width: 540px;
                    `}
                >
                    {content?.tagline}
                </SubTitle>
            </motion.div>
            <BlockHeaderSpacer />
            <div
                css={css`
                    border-radius: 16px;
                    border: 1px solid var(--light-blue-200, #dae5fb);
                `}
            >
                <div
                    css={css`
                        display: grid;
                        gap: 24px;
                        grid-template-columns: 2fr 3fr;
                        padding: 40px 80px;
                        ${lessThan(1)`
                            grid-template-columns: 1fr;
                            padding: 40px;
                        `}
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            text-align: left;
                            text-wrap: balance;
                            ${lessThan(1)`
                                text-align: center;
                                align-items: center;
                            `}
                        `}
                    >
                        <Badge color="blue">{content.blocks[0].label}</Badge>
                        <Title3>{content.blocks[0].title}</Title3>
                        <Paragraph>{content.blocks[0].description}</Paragraph>
                    </div>

                    <div
                        css={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        `}
                    >
                        <Hierarchy />
                    </div>
                </div>
                <div
                    css={css`
                        border-top: 1px solid var(--light-blue-200, #dae5fb);
                        display: grid;
                    `}
                >
                    <div
                        css={css`
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            ${lessThan(1)`
                            grid-template-columns: 1fr`}
                        `}
                    >
                        <div
                            css={css`
                                border-right: 1px solid
                                    var(--light-blue-200, #dae5fb);
                                padding: 40px 80px;
                                gap: 40px;
                                display: grid;

                                ${lessThan(1)`
                                    padding: 40px;
                                    border-right: none;
                                `}
                            `}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    text-align: center;
                                    text-wrap: balance;
                                `}
                            >
                                <Badge color="blue">
                                    {content.blocks[1].label}
                                </Badge>
                                <Title3>{content.blocks[1].title}</Title3>
                            </div>
                            <div
                                css={css`
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                `}
                            >
                                <SecurityFeatures />
                            </div>
                        </div>

                        <div
                            css={css`
                                padding: 40px 80px;
                                gap: 40px;
                                display: grid;

                                ${lessThan(1)`
                                    border-top: 1px solid var(--light-blue-200, #dae5fb);
                                    padding: 40px;
                                `}
                            `}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    text-align: center;
                                    text-wrap: balance;
                                `}
                            >
                                <Badge color="blue">
                                    {content.blocks[2].label}
                                </Badge>
                                <Title3>{content.blocks[2].title}</Title3>
                                <LinkButton
                                    href="/integrations"
                                    variant="primary"
                                    shape="pill"
                                    css={css`
                                        margin-top: 16px;
                                    `}
                                >
                                    All integrations
                                </LinkButton>
                            </div>
                            <div
                                css={css`
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                `}
                            >
                                <GlobeIntegrations />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    )
}
